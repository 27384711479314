import { Component, computed, HostListener, signal } from '@angular/core';
import { HotToastService, Toast } from '@ngxpert/hot-toast';

import { ApiService } from '../api/api.service';
import { WebauthnService } from '../auth/webauthn.service';
import { ApiResponse } from '../models/ApiResponse.model';
import { ApiUser } from '../models/Profile.model';
import { StateService } from '../state/state.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'fini-security-check',
  templateUrl: './security-check.component.html',
  styleUrls: ['./security-check.component.scss'],
  standalone: false,
})
export class SecurityCheckComponent {
  constructor(
    private state: StateService,
    private api: ApiService,
    private toast: HotToastService,
    private webauthn: WebauthnService,
  ) { }

  showSecurityCheck = signal(false);
  user = this.state.get<ApiUser>('user');

  provider = signal<'app' | 'password' | 'email' | 'passkey' | ''>('');
  providerIcon = computed(() => {
    switch (this.provider()) {
      case 'app':
        return 'devices';
      case 'password':
        return 'key';
      case 'email':
        return 'mail_lock';
      case 'passkey':
        return 'fingerprint';
      default:
        return 'lock';
    }
  });
  code = signal('');

  private checking?: Toast<any>;

  @HostListener('window:security:check') async SecurityCheck() {
    if (!this.checking?.visible)
      this.checking = this.toast.loading('Verifying your identity', {
        id: 'security-check',
      })?.getToast();

    if (!this.showSecurityCheck()) {
      if (!window?._debug?.securityCheck) {
        const required = await this.securityCheckRequired();
        if (!required) {
          if (this.checking !== undefined) {
            this.checking.type = 'success';
            this.checking.duration = 1000;
          }
          return;
        }
      }

      if (this.checking !== undefined) {
        this.checking.message = 'Verify your identity to continue';
        this.checking.type = 'warning';
      }
    }
    this.showSecurityCheck.set(true);
  }

  private async securityCheckRequired() {
    if (!this.user) {
      return true;
    }

    const res = await lastValueFrom(this.api.post<ApiResponse<{
      secured: boolean;
    }>>('session/security/check'));
    if (!res.secured || !res.success) {
      return true;
    }

    return false;
  }

  check() {
    this.api
      .post<ApiResponse>('session/security', {
        code: this.code(),
        provider: this.provider(),
      })
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.showSecurityCheck.set(false);
            this.code.set('');
            this.provider.set('');
            window.dispatchEvent(new CustomEvent('security:check:success'));
          } else {
            this.toast.error(res.message);
          }
        },
        error: console.error,
      });
  }

  async passkey_auth() {
    const res = await this.webauthn.authenticateSecurityCheck();
    if (res) {
      this.showSecurityCheck.set(false);
      // this.code.set(res);
      window.dispatchEvent(new CustomEvent('security:check:success'));
    } else {
      this.toast.error('Failed to authenticate');
    }
  }

  async request_email() {
    const res = await lastValueFrom(this.api.post<ApiResponse>('session/security/request', {
      provider: 'email',
    }));
    if (res.success) {
      this.toast.success('Email sent');
    } else {
      this.toast.error(res.message);
    }
  }
}
