import { Component } from '@angular/core';

@Component({
  selector: 'fini-hc-log-config-suggestions',
  imports: [],
  templateUrl: './hc-log-config-suggestions.component.html',
  styleUrl: './hc-log-config-suggestions.component.scss'
})
export class HcLogConfigSuggestionsComponent {

}
