import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ErrorHandler,
  inject,
  NgModule,
  provideAppInitializer,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import * as Sentry from '@sentry/angular';
import { NgxStripeModule, provideNgxStripe } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { ApiInterceptor } from 'src/libs/api/api.interceptor';
import { GuidesModule } from 'src/libs/guides/guides.module';
import { HeaderComponent } from 'src/libs/header/header.component';
import { NavMenuComponent } from 'src/libs/nav-menu/nav-menu.component';
import { NotificationModule } from 'src/libs/notification/notification.module';
import { DiscordLoginProvider } from 'src/libs/oauth/providers/Discord.login-provider';
import { OnboardingModule } from 'src/libs/onboarding/onboarding.module';
import { PaginationModule } from 'src/libs/pagination/pagination.module';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { SecurityCheckModule } from 'src/libs/security-check/security-check.module';
import { SnowfModule } from 'src/libs/snow/snow.module';

import { BannersComponent } from '../libs/banners/banners.component';
import { FeatureLimiterModule } from '../libs/feature-limiter/feature-limiter.module';
import { HoverCardModule } from '../libs/hover-card/hover-card.module';
import { TooltipModule } from '../libs/tooltip/tooltip.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HelpmeInfoComponent } from 'src/libs/helpme-info/helpme-info.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HeaderComponent,
    HomepageComponent,
    AuthenticationComponent,
    NotFoundComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipesModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    NotificationModule,
    ScrollingModule,
    SecurityCheckModule,
    GuidesModule,
    PaginationModule,
    OnboardingModule,
    HoverCardModule,
    NgxStripeModule.forRoot(),
    FeatureLimiterModule,
    BannersComponent,
    SnowfModule,
    HelpmeInfoComponent
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: DiscordLoginProvider.PROVIDER_ID,
            provider: new DiscordLoginProvider(
              environment.loginProviders.discord.clientId,
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      const initializerFn = (() => () => {
        inject(Sentry.TraceService);
      })();
      return initializerFn();
    }),
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideHotToastConfig({
      position: 'top-center',
      theme: 'toast',
      stacking: 'depth',
    }),
    provideNgxStripe(),
  ],
})
export class AppModule { }
