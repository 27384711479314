<div class="page-title mb-4">
  <h1>{{ 'server.title' | translate }}</h1>
  <span>{{ 'server.subtitle' | translate }}</span>
</div>

<div class="licenses">
  @if ('OWNER' | permission | async) {
    <div class="content-group section">
      <fini-server-addons></fini-server-addons>
    </div>
  }

  @if ('IPAUTH.VIEW' | permission | async) {
    <div class="content-group section">
      <fini-server-ips></fini-server-ips>
    </div>
  } @else {
    <fini-permission-required
      permission="IPAUTH.VIEW"
      [unauthorised-title]="'licenses.ips.title' | translate"
    >
      <div class="content-group section">
        <fini-server-ips></fini-server-ips>
      </div>
    </fini-permission-required>
  }

  <div class="content-group section">
    <h2 class="sub-header">{{ 'licenses.downloads' | translate }}</h2>
    @if (loading.downloads()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
      <div class="row downloads">
        <div class="col-12 list">
          @if (downloadsAgreed()) {
            <table class="fini-table no-padding w-100">
              <thead>
                <tr>
                  <td>{{ 'licenses.name' | translate }}</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let download of downloads()">
                  <td class="w-100">{{ download.name }}</td>
                  <td>
                    <button
                      class="button primary small"
                      (click)="downloadFile(download)"
                      [disabled]="loadingIds().includes(download.id)"
                    >
                      {{ 'licenses.download' | translate }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          } @else {
            <p>
              {{ 'licenses.agreement.title' | translate }}
            </p>
            <p>
              {{ 'licenses.agreement.details' | translate }}
            </p>
            <button class="button primary fab" (click)="downloadsAgree()">
              {{ 'licenses.agreement.accept' | translate }}
            </button>
          }
        </div>
      </div>
    }
  </div>

  @if ('user' | state | async | access: 'ADMIN') {
    <div class="content-group section">
      <h2 class="sub-header">Server feature flags</h2>
      @if (loading.features()) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      } @else {
        <div class="feature-grid">
          <div *ngFor="let feature of features()" class="feature-item">
            <span>{{ feature.name }}</span>
            <mat-slide-toggle
              [hideIcon]="true"
              type="bool"
              [(ngModel)]="feature.enabled"
            >
            </mat-slide-toggle>
          </div>
        </div>
        <button class="button primary mt-4" (click)="saveFeatures()">
          Save
        </button>
      }
    </div>

    <div class="content-group section">
      <fini-server-file-whitelist
        *ngIf="'fivem' | game | async"
      ></fini-server-file-whitelist>
    </div>
  }
</div>
