<fini-modal *ngIf="showHelpmeInfo()" (closed)="showHelpmeInfo.set(false)">
  <div class="std-modal">
    <h2>Need Help?</h2>
    <div class="content-group">
      <div class="help-card">
        <div class="fini-icon-svg discord-icon"></div>
        <div class="help-text">
          Contact us on Discord.
          <a href="https://discord.gg/finiac" target="_blank">Join our Discord</a>
        </div>
      </div>
    </div>
  </div>
</fini-modal>
