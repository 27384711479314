<div class="config-tooltip">
  <p>
    AI config suggestions suggest changes to your config based on the logs and
    your current anti-cheat configuration.
  </p>
  <p>
    If this detection is a <b>false positive</b> you can use fix it using the AI
    suggestions below.
  </p>
</div>
