@if (loadingComplete()) {
  @if (permissionGranted()) {
    <ng-content></ng-content>
  } @else {
    @switch(UIType()) {
      @case('content-group') {
      <div class="content-group d-flex flex-row align-items-center justify-content-start gap-4 fixed-height-permreq">
        <span class="material-symbols-outlined">security</span>
        <div>
          <h2 class="sub-header">{{unauthorisedHeader()}}</h2>
          <p class="no-margin">You're missing the required <code>{{ permission() }}</code> permission to access this resource.</p>
        </div>
      </div>
      }
    }
  }
}
