<fini-modal *ngIf="showSecurityCheck()">
  <div class="std-modal">
    <div class="d-flex justify-content-between align-items-center relative">
      <div class="d-flex align-items-center justify-content-start gap-4">
        <button class="button secondary small back-button" [class.visible]="provider() !== ''" (click)="provider.set('')">
          <i class="material-symbols-outlined">arrow_back</i>
        </button>
      <h2 [class.shifted]="provider() !== ''">Security Check</h2>
      </div>
      <i class="material-symbols-outlined provider">{{ providerIcon() }}</i>
    </div>
    <div class="content-group">
      @if (provider() === "") {
        <p>Please select a method to continue.</p>
        <div class="d-flex flex-column gap-2 align-items-center justify-content-between">
          <button class="button small btn-option" (click)="provider.set('passkey')">
            <i class="material-symbols-outlined">fingerprint</i>
            <span>Passkey</span>
          </button>
          <button class="button small btn-option" (click)="provider.set('app')">
            <i class="material-symbols-outlined">devices</i>
            <span>App</span>
          </button>
          <button class="button small btn-option" (click)="provider.set('email');request_email()">
            <i class="material-symbols-outlined">mail_lock</i>
            <span>Email</span>
          </button>
          <button class="button small btn-option" (click)="provider.set('password')" [disabled]="true">
            <i class="material-symbols-outlined">key</i>
            <span>Password</span>
          </button>
        </div>
      }
      <ng-container *ngIf="provider() == 'password'">
        <p>Please enter your password to continue.</p>

        <input
          type="password"
          class="input-text w-100"
          [ngModel]="code()"
          (ngModelChange)="code.set($event)"
          placeholder="Password"
          (keyup.enter)="check()"
        />
      </ng-container>
      <ng-container *ngIf="provider() == 'app'">
        <p>
          Please enter the code from your authenticator
          app to continue.
        </p>
        <input
          type="text"
          class="input-text w-100"
          [ngModel]="code()"
          (ngModelChange)="code.set($event)"
          placeholder="MFA code"
          (keyup.enter)="check()"
        />
      </ng-container>
      <ng-container *ngIf="provider() == 'email'">
        <p>
          We have emailed you a one time passcode. Please
          enter it below to continue.
        </p>
        <input
          type="text"
          class="input-text w-100"
          [ngModel]="code()"
          (ngModelChange)="code.set($event)"
          placeholder="Email code"
          (keyup.enter)="check()"
        />
      </ng-container>
      <ng-container *ngIf="provider() == 'passkey'">
        <p>
          Click the button below to initiate passkey authentication.
        </p>
        <button class="button small btn-passkey" (click)="passkey_auth()">
          <i class="material-symbols-outlined">fingerprint</i>
          <span>Authenticate</span>
        </button>
      </ng-container>
    </div>
    @if (provider() !== '' && provider() !== 'passkey') {
      <div class="options d-flex justify-content-between">
        <button class="button primary" (click)="check()">Submit</button>
      </div>
    }
  </div>
</fini-modal>
