import { Component, HostListener, signal } from '@angular/core';
import { ModalModule } from '../modal/modal.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fini-helpme-info',
  imports: [CommonModule, ModalModule],
  templateUrl: './helpme-info.component.html',
  styleUrl: './helpme-info.component.scss'
})
export class HelpmeInfoComponent {

  constructor() { }

  showHelpmeInfo = signal(false);
  metadata: { [key: string]: any } = {};

  @HostListener('window:openHelpMenu', ['$event'])
  openHelpMenu(event: CustomEvent) {
    console.log('openHelpMenu event from:', event.detail.from);
    this.showHelpmeInfo.set(true);
    this.metadata = event.detail;
  }
}
