import { AfterViewInit, Component, input, OnInit, signal } from '@angular/core';
import { PermissionService } from '../permission/permission.service';
import { lastValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { CustomPipesModule } from '../pipes/custom-pipes.module';

type UIType = 'content-group' | 'button' | 'link';
@Component({
  selector: 'fini-permission-required',
  imports: [CommonModule, CustomPipesModule],
  templateUrl: './permission-required.component.html',
  styleUrl: './permission-required.component.scss'
})
export class PermissionRequiredComponent implements OnInit, AfterViewInit {
  constructor(private perm: PermissionService) { }

  permission = input(null);
  permissionGranted = signal(false);

  unauthorisedHeader = input<string>('Unauthorised', {
    alias: 'unauthorised-title'
  });
  UIType = input<UIType>('content-group', {
    alias: 'ui-type',
  });
  loadingComplete = signal(false);

  async ngOnInit() {
    if (await lastValueFrom(this.perm.hasPermission(this.permission()))) {
      this.passthrough();
    }
  }

  ngAfterViewInit() {
    this.loadingComplete.set(true);
  }

  passthrough() {
    this.permissionGranted.set(true);
  }
}
