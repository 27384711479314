import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HotToastService } from '@ngxpert/hot-toast';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { ApiService } from 'src/libs/api/api.service';
import { ModalModule } from 'src/libs/modal/modal.module';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';
import { ApiConfigIpList, ApiIpEntry } from 'src/libs/models/Configs.model';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { TooltipModule } from 'src/libs/tooltip/tooltip.module';

@Component({
  selector: 'fini-server-ips',
  imports: [
    CommonModule,
    FormsModule,
    MatChipsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    CustomPipesModule,
    MatProgressBarModule,
  ],
  templateUrl: './server-ips.component.html',
  styleUrls: ['./server-ips.component.scss'],
})
export class ServerIPsComponent {
  constructor(
    private api: ApiService,
    private toast: HotToastService,
  ) {}

  ipList$ = this.loadList();
  maxIps$ = new BehaviorSubject(3);
  atLimit$ = new BehaviorSubject(false);

  addNew$ = new BehaviorSubject(false);
  editIp = signal(null);
  loading$ = new BehaviorSubject(false);

  newIp = {
    ipAddr: '',
    alias: '',
  };

  loadList() {
    return this.api.post<ApiConfigIpList>('ipauth/list').pipe(
      tap((res) => {
        this.maxIps$.next(res.max_ips);
        this.atLimit$.next(res.list?.length >= res.max_ips);
      }),
      map((res) => res?.list || []),
    );
  }

  remove(ip: ApiIpEntry) {
    this.loading$.next(true);
    this.api
      .post<ApiResponse>('ipauth/remove', { id: ip.id })
      .subscribe((res) => {
        if (res.success) {
          this.toast.success('IP removed successfully!');
          this.ipList$ = this.loadList();
        } else {
          this.toast.error('Failed to remove IP ' + res.message);
        }
        this.loading$.next(false);
      });
  }

  save() {
    this.loading$.next(true);
    this.api.post<ApiResponse>('ipauth/add', this.newIp).subscribe((res) => {
      if (res.success) {
        this.toast.success('New IP whitelisted successfully!');
        this.ipList$ = this.loadList();
      } else {
        this.toast.error('Failed to whitelist IP: ' + res.message);
      }
      this.close();
    });
  }

  close() {
    this.loading$.next(false);
    this.addNew$.next(false);
    this.editIp.set(null);
    this.newIp = {
      ipAddr: '',
      alias: '',
    };
  }

  edit(ip: ApiIpEntry) {
    this.editIp.set(ip);
  }

  modify() {
    this.loading$.next(true);
    const ip = this.editIp();
    this.api
      .post<ApiResponse>('ipauth/modify', {
        id: ip.id,
        alias: ip.alias,
      })
      .subscribe((res) => {
        if (res.success) {
          this.toast.success(res.message);
          this.ipList$ = this.loadList();
        } else {
          this.toast.error('Failed to update: ' + res.message);
        }
        this.loading$.next(false);
      });
  }

  max(...nums: number[]) {
    return Math.max(...nums);
  }
}
